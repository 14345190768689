
.hero-container{
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-video-background{
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
}

.hero-container-home{
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0px;
}

.hero-video-background-home{
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
}

.info-hero{
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
}

.info-hero-monedas{
    justify-content: center;
}

.info-hero h1{
    text-align: center;
    font-family: "Ubuntu";
    font-weight: 600;
    color: #fff;
    margin-bottom: 15px;
    font-size: 50px;
}

.info-hero h5{
    text-align: center;
    font-family: "Ubuntu";
    color: #fff;
    max-width: 60%;
}
.video-container-hero{
    height: 100%;
}

.video-container-hero .hero-video-background-home{
    object-position: 0px -150px;
}

@media only screen and (max-width: 768px) {
    .video-container-hero .hero-video-background-home {
        object-position: -55px 0px;
    }
   .comision-container .hero-video-background-home{
        top: 0;
        bottom: 0;
    }
}

