/* SLIDE COSTOS */
/* SLIDE COSTOS */

.comentarios-clientes-comision {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px;
    border-radius: 25px;
    border: 2px solid white;
}

.titulo-comision {
    margin: 0px;
    color: white !important;
    font-weight: 500;
    font-size: 20px;
}

.descripcion-comision {
    width: 100%;
    margin: 0px;
    color: white !important;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.3em;
    max-width: 449px;
}

.container-flex-row-start {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
}

.child-container-comision{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
}

.line-separate{
    width: 100%;
    color: #DFDFDF;
}

.container-comision-text{
    width: 100%;
    text-align: left;
}

.container-comision-subtext{
    width: 100%;
    text-align: left;
    color: white;
    line-height: 1.5em;
}

.container-comision-subtext p{
    margin: 15px 0px;
    line-height: 1.3em;
}

.container-comision-subtext p span{
    text-decoration: underline;
    text-underline-offset: 5px;
}

.container-comision-subtext span a{
    color: white !important;
}

.tittle-web{
    margin: 0px;
    font-weight: 800;
    color: white;
}

.section-slider-comision {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.comision-container .child-container-slide{
    height: 200vh;
}

.slider-section-container-comision {
    position: sticky;
    margin-top: 80px;
    padding-top: 120px;
    padding-bottom: 100px;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    flex-wrap: wrap;
    row-gap: 80px;
    width: 100%;
    top: 0;
}

.slider-secction-text-comision {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide-comision {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.container-flex-row .video-placas{
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.panel.activo,.panel.active-prev,.panel.active-pos{
    opacity: 1!important;
}
.panel{
    opacity: 0;
}

.comisiones .container-flex-row-start img {
    min-width: 30px;
    min-height: 30px;
    height: auto;
}

.comisiones-slider1, .comisiones-slider2 {
    width: 100%;
    display: flex;
    flex-direction: column;
}


@media only screen and (max-width: 1000px) {

    .section-slider-comision {
        margin: 20px auto;
        width: 100%;
    }
    .comision-container .child-container-flex-row{
        width: 85%;
        flex-direction: column;
        row-gap: 20px;
    }
    .comision-container .child-container-slide{
        height: auto;
    }
    .comision-container .child-container-slide .slider-section-container-comision{
        position: relative;
    }

}

@media (min-width:1024px) and (max-width: 1440px) {
    .comision-container .comentarios-clientes-comision{
        width: 100%;
    }

   .comision-container .comentarios-clientes-comision{
    padding: 10px;
   }

    .comision-container .container-flex-row-start div img{
        width: 30px;
        height: 30px;
    }

    .comision-container .container-flex-row-start .titulo-comision{
        font-size: 18px;
    }
    .comision-container .container-flex-row-start .descripcion-comision{
        font-size: 16px;
    }
    .comision-container .child-container-comision .tittle-web{
        font-size: 22px;
    }
    .comision-container .tittle-comision-web{
        font-size: 25px;
    }
}


@media only screen and (max-width: 768px) {
    .comision-container .child-container-text {
        width: 100%!important;
    }

    .comisiones-slider1, .comisiones-slider2 {
        align-items: center;
    }
}
