.container-botones {
    column-gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.container-button-border {
    font-size: 0.9em;
    font-weight: 500;
    width: 150px;
    padding: 10px 25px;
    border: 1px solid white;
    border-radius: 25px;
    background-color: transparent;
    color: white;
}

.container-button-border:hover {
    background-color: #16AC7D;
    color: #fff;
    transition: all .2s ease-in;
}


.child-comerciantes-items {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.child-comerciantes-items .child-container-text {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.child-comerciantes-items-woman {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white !important;
}


.child-comerciantes-items-woman .child-container-text {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.container-flex .container-card-flex-comerciante {
    width: 32%;
    display: flex;
    flex-wrap: wrap;

}

.comerciantes-container .container-card-flex-comerciante .card-text {
    width: 75%;
    color: white !important;
}

.comerciantes-container .child-container-media {
    width: 50%;
}

.comerciantes-container .child-container-text {
    width: 50%;
}

.comerciantes-container .tam-section-xl {
    padding: 100px 0px;
}

.comerciantes-container .section-descargar-bouncher {
    padding: 0px
}

.comerciantes-container .card-image {
    padding-top: 5px !important;
    width: 25%;
    text-align: center;
}

.form-step .modal-dialog .modal-content {
    background-color: black !important;
    background-image: url("https://res.cloudinary.com/dc1bgmjr9/image/upload/v1674832109/WALLET%20BLACK/fondo-form_qmx47f.png");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: auto;
    border: 1px solid white;
    border-radius: 20px;
}

.form-step {
    backdrop-filter: blur(8px);
}

.form-step .modal-body {
    padding: 50px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.modal-body form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.container-form-step {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
}

.container-col-form-step {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
}

.container-button-step {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    row-gap: 15px;
    column-gap: 15px;
}

.boton-cerrar {
    border: none !important;
    outline: none;
    font-weight: 600;
    background-color: transparent !important;
    color: white !important;
    position: absolute;
    top: 20px;
    right: 20px;
}

.container-form-step div {
    width: 100%;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    width: 100%;
    color: white !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    width: 100%;
    color: rgb(216, 216, 216) !important;
    font-weight: 200 !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: white !important;
    border-radius: 10px !important;
    color: white !important;
}

.MuiTextField-root label {
    color: #e3e3e3 !important;
}

.input-login {
    color: white !important;
}

.MuiInputBase-input {
    width: 100%;
    color: white !important;
}

.MuiFormControl-root {
    width: 100% !important;
}

.container-form-step div {
    width: 100%;
}


.container-col-form-step {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
    row-gap: 15px;
}

.container-col-form-step div {
    width: 100%;
}

.modal-body form {
    row-gap: 15px;
}

input:-internal-autofill-selected {
    background-color: #16AC7D !important;
}

.error {
    font-size: 14px;
    font-weight: 500;
    color: red;
    margin: 0px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
    background-color: transparent !important;
}

.check-form-enviado {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;
    padding: 32px 0;
}

.container-nosotros .container-promo {
    width: 100% !important;
    height: 100vh;
    justify-content: space-evenly !important;
    display: flex;
    flex-direction: column;
}

.container-promo .child-container-text {
    width: 40%;
}

.container-promo .child-container-text h1 {
    font-size: 3.5em;
}

.container-promo .child-container-flex-row {
    width: 85% !important;
    justify-content: center !important;
    column-gap: 20px !important;
}

.container-promo .form-step-promo {
    background-color: black !important;
    background-image: url(https://res.cloudinary.com/dc1bgmjr9/image/upload/v1674832109/WALLET%20BLACK/fondo-form_qmx47f.png);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: auto;
    border: 1px solid white;
    border-radius: 20px;
}

.form-step-promo {
    padding: 50px !important;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.form-step-promo form {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

/* .logo-black-promo img {} */

@media only screen and (max-width: 768px) {
    .comerciantes-container .child-container-text{
        padding: 0px 30px;
    }

    .container-nosotros .container-promo {
        height: auto;
        row-gap: 40px;
    }

    .container-promo .child-container-text {
        width: 95% !important;
    }

    .container-promo .child-container-text h1 {
        font-size: 1.6em !important;
    }

    .container-promo .child-container-flex-row {
        width: 90% !important;
        row-gap: 45px !important;
    }

    .form-step-promo {
        padding: 40px 20px !important;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }

    .promo-enviado {
        width: 90% !important;
    }

    .form-step .modal-body {
        padding: 40px 25px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .child-comerciantes-items {
        width: 90%;
        display: flex;
        flex-direction: column-reverse !important;
        justify-content: space-between;
        align-items: center;
        row-gap: 40px;
    }

    .container-form-step {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
    }

    .check-form-enviado {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        row-gap: 10px;
    }

    .check-form-enviado img {
        width: 60px;
    }

    .child-comerciantes-items .child-container-text {
        width: 100%;
    }

    .child-comerciantes-items-woman {
        width: 85%;
        display: flex;
        flex-direction: column !important;
        justify-content: space-between;
        align-items: center;
    }

    .comerciantes-container .child-comerciantes-items-woman .child-container-text {
        width: 100%;
    }

    .comerciantes-container .container-flex{
        column-gap: 0px;
    }

    .comerciantes-container .container-flex .container-card-flex-comerciante {
        width: 50%;
        flex-direction: column;
        row-gap: 10px;
    }

    .container-card-flex-comerciante .card-text{
        width: 90%!important
    }

    .comerciantes-container .container-flex {
        row-gap: 25px;
    }

    .comerciantes-container .container-card-flex-comerciante .card-text {
        width: 100%;
    }

    .card-image img {
        width: 25px;
        height: 25px;
    }

    .reverse-row .child-container-flex-row {
        flex-direction: column !important;
    }

    .child-container-media video {
        width: 100%;
    }

    .image-pre-slider-container {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .container-nosotros .child-container-media video {
        height: 400px;
        object-fit: cover;
        object-position: -35px 0px;
    }

    .container-navbar-comerciantes {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .container-navbar-comerciantes {
        width: 85%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        row-gap: 10px;
    }

    .active-comercio {
        width: 50%;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        color: white;
        text-decoration: none;
    }

    .items-comerciante {
        width: 50%;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        color: #ffffff85 !important;
        text-decoration: none;
    }

    .container-nav-comerciante {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .container-navbar-comerciantes .item-tab-menu {
        width: 50% !important;
        text-align: center;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.473);
        border-radius: 0px !important;
        border-bottom: 2px solid rgba(255, 255, 255, 0.473) !important;
    }

    .item-tab-menu.active {
        text-align: center;
        align-items: center;
        justify-content: center;
        color: white !important;
        border-bottom: 2px solid #fff !important;
    }

    .comerciantes-container .gradient-section .child-container-flex-row {
        width: 100%;
    }

    .comerciantes-container .child-container-text {
        width: 80%;
    }

    .comerciantes-container .child-comerciantes-items .child-container-text,
    .comerciantes-container .child-comerciantes-items-woman .child-container-text {
        width: 100%;
    }

    .comerciantes-container .tam-section-xl,
    .comerciantes-container .child-comerciantes-items-woman {
        padding: 40px 0px;
    }

    .comerciantes-container .child-container-media {
        width: 100%;
    }

    .comerciantes-container .child-container-media video {
        object-fit: cover;
        width: 100%;
        height: 400px;
        object-position: 5px 0px;
    }


}