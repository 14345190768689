.politicas-privacidad {
    padding: 70px 0;

    .politicas-content {
        h1 {
            font-size: 32px;
            font-weight: 700;
            color: white;
            text-align: center;
            margin-bottom: 32px;
        }

        h2 {
            font-size: 24px;
            font-weight: 500;
            color: white;
            margin: 24px 0 16px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            color: white;
            margin-bottom: 14px;
        }

        ul {
            list-style: inside;
            margin-bottom: 14px;
            margin-left: 32px;

            li {
                font-size: 16px;
                font-weight: 400;
                color: white;
                margin-bottom: 14px;
            }
        }
    }

    @media (max-width: 700px) {
        padding: 48px 0;

        .politicas-content {
            h1 {
                font-size: 24px;
            }

            h2 {
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }

            ul {
                margin-left: 24px;

                li {
                    font-size: 14px;
                }
            }
        }
    }
}