.container-footer {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.child-container-footer {
    width: 100%;
    max-width: 1550px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.container-left-footer {
    width: 40%;
    column-gap: 20px;
}

.footer-flex-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 11px;
}

.footer-flex-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 90px;
}

.footer-icons-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 6px;
}

.footer-icons-flex-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 15px;
}

.footer-so-flex-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 15px;
}

.style-copyright {
    font-size: 0.75em;
    font-weight: 500;
    color: white;
}

.style-copyright.copyright-responsive {
    display: none;
}

@media (max-width: 1550px) {
    .child-container-footer {
        width: 95%;
    }
}

@media only screen and (max-width: 768px) {
    .container-footer {
        height: auto;
    }

    .footer-flex-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .child-container-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .footer-flex-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    .footer-icons-flex-col {
        display: flex;
        flex-direction:column;
        justify-content:center;
        align-items: center;
        gap: 15px;
    }

    .footer-so-flex-col {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
    }

    .footer-flex-col .items-navbar{
        color: white !important;
    }

    .footer-logo {
        display: none;
    }

    .footer-platforms-socials {
        gap: 12px;
    }

    .style-copyright{
        margin: 0px;
        margin-bottom: 40px;
        color: #ffffff60;
        font-size: 0.9em;
        font-weight: 400;
    }

    .style-copyright.copyright-responsive {
        display: block;
    }

    .items-navbar {
        text-align: center;
    }
}