.container-navbar {
    height: 20vh;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    backdrop-filter: blur(5px);
}

.navbar-toggler {
    border: none !important;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

/* ITEMS DE NAVBAR */
.items-navbar {
    align-items: center;
    display: flex;
    margin: 0px;
    padding: 0px 10px;
    color: #666666;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.2em;
    text-transform: uppercase !important;
    text-decoration: none !important;
    transition: color .3s ease-in-out;
}

.items-navbar:hover {
    color: white;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.2em;
}

/* ITEMS NAVBAR ACTIVE */
@media (min-width: 1200px) {
    .active {
        padding: 0px 10px;
        color: white !important;
        font-size: 1;
        font-weight: 500;
        line-height: 1.2em;
        text-decoration: none !important;
        align-items: center;
        display: flex;
    }   
}

.container-navbar-fixed{
    height: 20vh;
    position: sticky;
    left: 0;
    z-index: 99999999999;
    right: 0;
    top: 0;
    background-color: #000;
}



/* OFFSET CANVAS MOBILE */
.offcanvas.offcanvas-top.show {
    border-radius: 0px 0px 40px 40px !important;
}

.fade.offcanvas-backdrop.show {
    filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: transparent !important;
}

.offcanvas-backdrop.show {
    opacity: 1 !important;
    backdrop-filter: blur(5px);
    filter: blur(2px);
}

.button-comercio {
    text-align: center;
    padding: 10px 15px;
    border: 2px solid #666666;
    border-radius: 25px;
    transition: all .3s ease-in-out;
}

.button-comercio:hover {
    border-color: #FFF;
}

.button-comercio-active {
    /* width: 48%; */
    text-align: center;
    padding: 10px 15px;
    border: 2px solid white;
    border-radius: 25px;
}

.offcanvas-body.mobile-navbar-container {
    border-radius: 0px 0px 40px 40px !important;
    padding: 0px;
}

.navbar-brand {
    border: none !important;
    outline: none !important;
}

.btn-close {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNyAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuOTM3NSAxNUwxNS4wNjI1IDFaTTE1LjA2MjUgMTVMMS45Mzc1IDFaIiBmaWxsPSIjMDgwODA4Ii8+CjxwYXRoIGQ9Ik0xLjkzNzUgMTVMMTUuMDYyNSAxTTE1LjA2MjUgMTVMMS45Mzc1IDEiIHN0cm9rZT0iIzA4MDgwOCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==') !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

@media only screen and (max-width: 768px) {
.container-navbar{
    height: 125px;
}

    .container-navbar-fixed{
        height: 20vh;
        position: sticky;
        left: 0;
        z-index: 99999999999;
        right: 0;
        top: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }

    .offcanvas-body {
        border-radius: 0px 0px 40px 40px !important;
    }

    .offcanvas.offcanvas-top {
        border-radius: 0px 0px 40px 40px !important;
    }

    .active {
        color: black !important;
    }

    .container-menu-mobile {
        row-gap: 12px;
        padding-left: 15px;
        padding-top: 50px;
        padding-bottom: 20px;
    }

    .navbar-toggler {
        margin-top: -13px !important;
    }

    .button-comercio {
        width: 160px;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        border: 2px solid #666666;
        border-radius: 25px;
    }

    .button-comercio-active {
        width: 160px;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        border: 2px solid black;
        border-radius: 25px;
    }

    .btn-close:focus {
        box-shadow: none !important;
    }

    .btn-close {
        background-size: 50%;
        position: absolute;
        top: 30px;
        left: -30px;
        width: 1.2em;
        opacity: 1 !important;
    }

    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        width: 90% !important;
    }

    .offcanvas.offcanvas-top {
        border-radius: 0px 0px 40px 40px !important;
        height: 40vh !important;
    }

    .mobile-navbar-container.offcanvas-body {
        display: flex;
        align-items: center;
    }

    .offcanvas.offcanvas-top .offcanvas-header {
        position: absolute;
        top: 0px;
        right: 0px;
    }

}

@media (max-height: 640px) and (max-width: 768px) {
    .offcanvas.offcanvas-top {
        height: 55vh !important;
    }
}

@media (min-width: 1550px) {

    .navbar>.container,
    .navbar>.container-fluid,
    .navbar>.container-lg,
    .navbar>.container-md,
    .navbar>.container-sm,
    .navbar>.container-xl,
    .navbar>.container-xxl {
        max-width: 1550px !important;
    }
}