.bgGradient{
    background: rgb(0, 0, 0);
    background: linear-gradient(270deg, rgba(26, 26, 26, 0.8) 0%, rgba(0, 0, 0, 1) 100%);
}

.bgAngular{
    margin: 0;
    padding: 0;
    height: 100vh;
    background: linear-gradient(310deg, #343434 1%, #080808 55%);
}

.bgAngularM{
    margin: 0;
    padding: 0;
    height: 100vh;
    background: linear-gradient(310deg, #343434 1%, #080808 35%);
}

.inputfile {
   width: 100%;
    opacity: 0;
    position: absolute;
    cursor: pointer;
    height: 100%;   
    border: solid 1px white;
  }

  .custom-file-label{
    font-size: 0.8em;
    font-weight: 500;
    color: white;
    border: solid 1px white;
    width: 100%;
    background-color: 'black';
    display: inline-block;
    padding: 2.6em 20px;
    border-radius: 5px;
    cursor: pointer;
  }


.container-home {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: black;
}

.gradient-section .child-container-media {
    align-items: flex-end;
}

.gradient-section-reverse .child-container-media {
    align-items: flex-start;
}

.home-container .child-container-flex-row {
    width: 80%;
    display: flex;
    align-items: center;
}

.child-container-media video {
    max-width: 100%;
}

.child-container-text h1 {
    font-size: 3em;
    font-weight: 800;
}

.child-container-text h2.title-flex {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 3em;
    font-weight: 800;
}

.child-container-text h2.title-flex img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: 0;
}

.icon-black {
    margin-top: -12px;
    margin-right: 10px;
    width: 150px;
}

.icon-black-left {
    margin-top: -15px;
    margin-left: 6px;
    width: 160px;
}

.bloque-hero-home {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.secction-text-background-video{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.secction-text-background-video img{
    width: 550px;
    height: auto;
}

.logo-black-scroll {
    margin-left: auto;
    margin-right: auto;
    width: 350px;
    display: block;
}

.ocultar-seccion {
    visibility: hidden;
}

.mostrar-seccion {
    visibility: visible;
}

.block-text-scroll {
    position: absolute;
    z-index: 1;
}

.scroll-text-sub {
    color: rgba(255, 255, 255, 0.884);
    text-align: center;
    margin: 20px 0px;
    font-size: 28px;
    font-weight: 500;
}

.scroll-seccion-botones {
    display: flex;
    column-gap: 35px;
    cursor: default;
}
.scroll-seccion-botones a{
    text-decoration: none;
    cursor: pointer;
}
.scroll-seccion-botones button {
    display: flex;
    background-color: #000;
    align-items: center;
    column-gap: 10px;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 10px 20px;
    width: 220px;
}

.scroll-seccion-botones button img {
    width: 40px;
}

.scroll-seccion-botones button div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.button-scroll-sub {
    color: #fff;
    font-size: 15px;
    margin: 0px;
    font-weight: 300;

}

.button-scroll-titulo {
    color: #fff;
    font-size: 25px;
    margin: 0px;
    line-height: 25px;
    font-weight: 500;
}

.seccion-video-tarjeta {
    position: relative;
    height: 75vh;
}

.video-tarjeta-text {
    color: #fff;
    text-align: center;
    position: absolute;
    bottom: 350px;
    left: 0;
    right: 0;

}

.video-tarjeta-text h5 {
    font-size: 3em;
    font-weight: 800;
}

.video-tarjeta-text p {
    font-size: 1.1em;
    font-weight: 400;
}

.container-button {
    font-size: 0.9em;
    font-weight: 500;
    text-decoration: none !important;
    padding: 10px 25px;
    border: 1px solid #3C3C3C;
    border-radius: 25px;
    background-color: #3C3C3C;
    color: white;
}

.container-button:hover {
    background-color: #16AC7D;
    color: #fff;
    transition: all .2s ease-in;
}


.container-medialuna {
    margin-bottom: -280px;
}

.container-media-middle {
    margin-bottom: -300px;
}

.container-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
}

.container-home-cards-top {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}

.container-home-cards-middle {
    margin-top: -10px;
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}

.slide4{
    background-image: url('https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693244291/WALLET%20BLACK/Slider/slide_4_zn1qba.svg');
    background-size: cover;
}

.slide3{
    background-image: url('https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693243515/WALLET%20BLACK/Slider/Group_508_uiobly.png');
    background-repeat: no-repeat;
    background-size: 105% 90%;
    background-position: center;
}

.container-home-cards-bottom {
    margin-top: -80px;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}

.child-home-cards {
    width: 15%;
    color: white !important;
    text-align: center;
}

.child-home-cards img {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.child-home-cards p {
    font-size: 0.9em;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    line-height: 1.2em;
}

.container-card-flex {
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.container-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
}

.card-image {
    width: 25%;
}

.container-card-flex .card-text {
    width: 60%;
    color: white !important;
}

.container-card-flex .card-text .tittle-card-security {
    font-weight: 700;
    font-size: 25px;
    color: white !important;
}

.card-text p {
    line-height: 1.2em;
}

/* SUMATE A BLACK CARDS */

.container-sumate-black {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 30px;
}

.container-sumate-black .child-sumate-black {
    color: white !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.child-sumate-black h5 {
    font-size: 2.5em;
}

.child-sumate-black p {
    font-size: 1.5em;
}

.icon-black-top {
    margin-top: -20px;
    margin-left: 6px;
    width: 180px;
}

.cards-suma-black {
    width: 15%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white !important;
    text-align: center;
    border: 1px solid white;
    border-radius: 15px;
    padding: 10px;
}

.cards-suma-black img {
    width: 45px;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.cards-suma-black p {
    font-size: 0.9em;
    font-weight: 300;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    line-height: 1.2em;
}

.line-separate-cards {
    width: 80px;
    border: 1px solid white
}

.store-sumate {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 30px;
}

.slider-fotos-sumate .swiper-wrapper {
    align-items: center;
}

.slider-fotos-sumate {
    height: 100%;
}

.slider-fotos-sumate .swiper-pagination {
    bottom: 15px !important;
}

.slider-fotos-sumate .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
}

.home-container .child-container-text h5 {
    font-weight: 700;
    font-size: 50px;
    color: white;
    max-width: 450px;
}


.home-container .child-container-text .titulo-con-imagen {
    align-items: center;
}

.home-hero .height-bloque-hero-home {
    height: 48vh;
}

@media only screen and (max-width: 768px) {
    .video-container-background-home video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .secction-text-background-video img{
        width: 280px;
    }
    /* .secction-text-background-video{
        height: 70vh;
    } */

    .text-web{
        font-size: 14px!important;
    }

    .icon-black {
        margin-top: -12px;
        margin-right: 10px;
        width: 120px;
    }

    .child-home-cards img {
        width: 50px;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .hamburguesa {
        width: 35px;
    }

    .child-container-text h1 {
        width: 100%;
        font-size: 3em;
        font-weight: 800;
    }

    .container-media-middle video {
        width: 100%;
    }

    .container-medialuna img {
        width: 100%;
    }

    .gradient-section .child-container-media {
        align-items: center;
    }

    .container-card-flex .card-text {
        width: 90%;
    }

    .container-card-flex {
        width: 48%;
        row-gap: 15px;
        flex-direction: column;
        justify-content: space-between !important;
        align-items: flex-start;
    }

    .container-flex {
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: 10px;
    }

    .card-text {
        row-gap: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .card-text p {
        font-size: 0.9em;
        font-weight: 300;
        margin: 0px !important;
    }

    .card-text h5 {
        font-size: 1.1em;
        margin: 0px !important;
    }

    .child-home-cards {
        width: 40%;
    }

    .store-sumate {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }

    .cards-suma-black {
        width: 100%;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white !important;
        text-align: center;
        border: 1px solid white;
        border-radius: 15px;
        padding: 10px;
    }

    .child-sumate-black {
        width: 50%;
        text-align: center;
    }

    .child-sumate-black h5 {
        font-size: 1.5em;
        line-height: 2.6em;
    }

    .child-sumate-black p {
        font-size: 1em;
    }

    .icon-black-top {
        margin-top: -20px;
        margin-left: 6px;
        width: 200px;
    }

    .slider-fotos-sumate .swiper-slide {
        justify-content: center !important;
        align-items: center;
        width: 90%;
    }

    .logo-black-scroll {
        width: 235px;
    }

    .scroll-text-sub {
        font-size: 24px;
    }

    .scroll-seccion-botones {
        flex-direction: column;
        row-gap: 10px;
        align-items: center;
    }

    .video-tarjeta-text h5 {
        font-size: 1.8em;
    }

    .seccion-video-tarjeta {
        position: relative;

    }

    .seccion-video-tarjeta.mostrar-seccion video {
        position: absolute !important;
    }

    .video-tarjeta-text {
        position: unset;
        margin-bottom: 50px;
        padding: 20px;
    }
    .video-container-mobile {
        margin-top: 50px;
        text-align: center;
    }
    .video-container-mobile video{
        width: 80%!important;
    }

    .home-container .child-container-flex-row {
        flex-direction: column-reverse !important;
    }

    .section-slider.slider-home {
        padding-left: 25px;
    }

    .seccion-video-tarjeta.mostrar-seccion .video-tarjeta-text {
        padding: 0px 22px;
    }

    .seccion-video-tarjeta.mostrar-seccion .video-tarjeta-text p {
        max-width: 80%;
        margin-right: auto;
        margin-left: auto;
    }

    .home-container  .child-container-text{
        width: 100%!important;
    }

    .home-container .container-flex-row.tam-margin-xl.gradient-section .child-container-flex-row {
        row-gap: 40px;
    }

    .home-container .container-flex-row.tam-margin-xl.gradient-section .child-container-flex-row .child-container-text .text-web {
        line-height: 28px;
    }

    .home-container .gradient-section .child-container-flex-row {
        flex-direction: column-reverse !important;
    }

    .home-container .container-flex-row.tam-margin-xl.gradient-section-reverse {
        margin-top: 80px;
    }

    .home-container .container-flex-row.tam-margin-xl.gradient-section-reverse .child-container-flex-row {
        row-gap: 50px;
        margin-bottom: 50px;
    }

    .home-container .container-flex-row.tam-margin-xl.gradient-section-reverse .child-container-flex-row .child-container-text h1 {
        text-align: right;
    }

    .home-container .container-flex-row.tam-margin-xl.gradient-section-reverse .child-container-flex-row .child-container-text .text-web {
        text-align: right;
    }

    .home-container .container-flex-row.tam-margin-xl.gradient-section-reverse .child-container-flex-row .child-container-text button {
        margin-left: auto;
    }

    .home-container .child-container-text h5 {
        color: #fff;
        font-size: 1.9em;
        font-weight: 800;
    }

    .home-container .child-container-text .titulo-con-imagen img {
        width: 130px;
        margin-bottom: 6px;
    }

    /* Carrousel Home Mobile */
    .slider-mobile-home {
        position: relative;
        height: 100vh;
        display: flex;
        align-items: flex-end;
        padding-bottom: 20px;
        margin-top: -150px;
    }

    .image-pre-slider-container {
        width: 100%;
        height: 600px;
        position: absolute;
        top: 0;
        margin-top: 140px;
    }

    .image-pre-slider-container video {
        width: 100%;
    object-fit: cover;
    height: 55%;
    }

    .image-pre-slider-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swipper-mobile-home.swiper {
        width: 100%;
        height: 300px;
        padding-top: 100px;
    }

    .swipper-mobile-home .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-weight: bold;
        color: #fff;

    }

    .swipper-mobile-home .child-home-cards h5 {
        font-size: 18px;
    }

    .swipper-mobile-home .child-home-cards p {
        font-size: 16px;
        font-weight: 400;
    }

    .swipper-mobile-home .child-home-cards {
        width: 90% !important;
    }

    .swipper-mobile-home .swiper-slide.swiper-slide-visible.swiper-slide-active,
    .swipper-mobile-home .swiper-slide.swiper-slide-visible.swiper-slide-prev,
    .swipper-mobile-home .swiper-slide.swiper-slide-visible.swiper-slide-next {
        opacity: 1 !important;
    }

    .swipper-mobile-home .swiper-slide {
        opacity: 0 !important;
    }

    /*FIN HOME CARROUSEL MOBILE*/

    .home-container .home-segurity-block .child-container-flex-row {
        flex-direction: column-reverse !important;
        row-gap: 50px;
    }

    .home-container .home-segurity-block .child-container-flex-row .child-container-text .card-image img {
        width: 35px;
        height: 35px;
        object-fit: contain;
    }

    .home-container .home-segurity-block .child-container-flex-row .child-container-text .card-image {
        width: 35%;
    }

    .home-container .home-segurity-block .child-container-flex-row .container-flex {
        width: 100%;
        justify-content: space-between;
    }

    .home-container .home-segurity-block .child-container-flex-row .container-card-flex {
        width: 48%;
    }

    .home-container .home-segurity-block .child-container-flex-row .container-card-flex .card-text h5 {
        font-size: 22px;
    }

    .home-container .home-segurity-block .child-container-flex-row .container-card-flex .card-text .tittle-card-security {
        font-size: 22px;
    }

    .home-container .home-segurity-block .child-container-flex-row .container-card-flex .card-text p {
        font-size: 16px;
    }

    .home-container .home-segurity-block .child-container-flex-row .child-container-text .container-flex {
        row-gap: 40px;
    }

    .home-container .home-segurity-block .child-container-flex-row .child-container-text {
        row-gap: 25px;
    }
    .container-navbar-fixed{
        z-index: 10;
    }

    .video-container{
        height: 100%;
    }
    .video-container video{
        width: 100%;
    }
    .video-container .hero-video-background-home {
        object-position: -20px -30px;
    }
    #v0 {
        top: 110px!important;
    }

    .hero-container-home {
        margin: 0 !important;
    }

    .home-hero .height-bloque-hero-home {
        height: 80vh;
    }
}









.container-scroll-video {
    height: 100vh;
}

#set-height {
    display: block;
}

#v0 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
}

p font-family helvetica {
    font-size: 24px;
}