
.align-slider-right {
    width: 90%;
    margin-left: auto;
}

.content-beneficios .slider-section-container{
    padding-bottom: 80px;
}

.slider-beneficios-bloque-fijo{
    flex-direction: column;
    align-items: flex-start;
}

.slider-beneficios-bloque-fijo.slider-secction-text h5{
    width: fit-content;
}

.slider-descuentos-puntos{
    position: relative;
    max-width: 340px;
    width: 100%;
    height: 100%;
}

.text-los-mejores-descuentos{
    width: 85%;
}

.slider-descuentos-black img{
width: 100%;
height: 50%;
object-fit: cover;
}

.slider-descuentos.slider-descuentos-black{
    height: 400px;
}

.slider-descuentos.slider-descuentos-black .slider-descuentos-box-description{
    height: 50%;
}


.box-puntos{
    top: 0;
    right: 20px;
    background-color: rgba(255, 255, 255, 0.534);
    position: absolute;
    padding: 15px;
    border-radius: 0px 0px 20px 20px;
}

.box-puntos span{
    font-size: 10px;
    font-family: "Ubuntu";
    font-weight: 500;
}

.box-puntos p{
    font-size: 20px;
    font-family: "Ubuntu";
    font-weight: 700;
    margin: 0px;
    line-height: 14px;
}

.logo-puntos-black{
    border-radius: 0px!important;
    margin: 10px 0px;
}

.logo-alohar{
    bottom:15px;
    right: 20px;
    position: absolute;
    width: 80px!important;
}

.imagen-completa-slider-puntos{
    object-fit: cover;
    height: 100%;
    border-radius: 25px 25px 25px 25px!important;
}

.overlay-block-hidden{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    padding: 25px;
    background-color: #000;
    border-radius: 0px 0px 25px 25px;
}

.slider-descuentos.slider-descuentos-puntos:hover .overlay-block-hidden{
    opacity: 1;
  }
  .overlay-block-hidden:hover{
    cursor: default;
  }

  .slider-descuentos.slider-descuentos-puntos:hover{
    border: 1px solid #fff;
    border-radius:25px;
  }
  

.slider-descuentos.slider-descuentos-puntos{
    position: relative;
}

.tarjeta-con-borde{
    border: 1px solid #fff;
    border-radius: 25px 25px 25px 25px;
}

.imagen-mitad-slider-puntos{
    max-height: 50%;
    object-fit: cover;
}

.slider-descuentos.slider-descuentos-puntos.tarjeta-con-borde {
    height: 100%;
}

.slider-beneficios-container .swipper-beneficios{
    height: 400px;
    
}

.titulo-con-imagen {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.titulo-con-imagen h5{
    margin-bottom: 0px;
}

.titulo-con-imagen img{
    display: block;
    max-width: 100%;
    height: auto;
}

.slider-puntos-box-description{
    padding: 25px;
}

.beneficios-page-container .child-container-slide .slider-section-container {
    gap: 113px;
    flex-wrap: nowrap;
}

@media (max-width: 720px) {
    .beneficios-page-container .child-container-slide .slider-section-container {
        gap: 51px;
        flex-wrap: wrap;
    }
}

.beneficios-page-container .slider-section-container .slider-secction-text {
    width: fit-content;
}

.slider-beneficios-bloque-fijo p{
    font-size: 20px;
    color: rgba(255, 255, 255, 0.877);
    max-width: 85%;
    margin-bottom: 36px;
}

.swipper-beneficios{
    align-items: center;
}

.slider-descuentos img{
    width: 100%;
    border-radius: 25px 25px 0px 0px;
    
}

.slider-descuentos-box-description{
    border-top: none!important;
    border: 1px solid #fff;
    border-radius: 0px 0px 25px 25px;
    padding: 15px;
}

.slider-descuentos-box-description h5{
    font-size: 25px;
}

/* slider-descuentos-bandas */
.slider-bandas{
    max-width: 550px;
}

.imagen-completa-slider-bandas{
    height: 100%;
    object-fit: contain;
}

.logo-entradauno{
    bottom:10px;
    left: 20px;
    position: absolute;
    width: 80px!important;
}

span.swiper-pagination-bullet{
    background-color: #fff;
}

.slider-section-text-bandas{
    width: 65%;
    padding-left: 50px;
}

.slider-section-swipper-bandas{
    width: 35%;
}

.slider-section-text-bandas p{
    max-width: 60%;
}

/* Slider con logo */
.swipper-beneficios-con-logo{
    border: 1px solid #fff;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    padding: 25px 10px;
    row-gap: 8px;
    text-align: center;
    max-height: 200px;
    min-height: 200px;
}

.swipper-beneficios-con-logo:has(a) a {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.box-image-logo-container{
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}



.swipper-beneficios-con-logo h5{
    color: rgba(255, 255, 255, 0.884);
}

.swipper-beneficios-con-logo p{
    margin: 0px;
    color: rgba(255, 255, 255, 0.884);
}

.titulo-slider-beneficio{
color: #fff;
font-size: 20px;
}

.slider-container-beneficios{
    margin: 100px 0px;
}

.slider-container-beneficios .swiper-wrapper:hover {
    cursor: grab;
}

.slider-container-beneficios .swiper-wrapper:active {
    cursor: grabbing;
}

.boton-ver-todos{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    border: none;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    color: #B8B8B8;
}

.slider-container-with-transparency{
      -webkit-mask-image: -webkit-gradient(linear, left top, 
    left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  }


  .ver-mas-mobile{
    right: 0;
    left: 0;
    bottom:10px;
    position: absolute;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
  }
  .ver-mas-mobile p{
    color: #fff;
    margin: 0;
  }


  @media only screen and (max-width: 720px) {
    /* slider-descuentos-puntos */
    .align-slider-right{
        margin: 0px auto;
        width: 100%;
    }
    .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.slider-beneficios-container.mySwiper {
        margin-left: 20px;
    }

    .slider-puntos-box-description{
        padding: 5px;
    }
    .slider-puntos-box-description .descripcion-comentario-cliente{
        padding: 5px;
        margin-top: 0px;
        font-size: 15px;
    }
    .slider-puntos-box-description  .titulo-comentario-cliente{
        padding: 5px;
    }
    .slider-beneficios-container .swipper-beneficios{
        height: 350px;
    }
    .slider-descuentos-puntos{
        max-width: 250px;
    }
    .slider-beneficios-bloque-fijo{
        align-items: flex-end;
        padding-right: 30px;
    }
    .slider-secction-text h5{
        color: #fff;
        font-size: 34px;
        font-weight: 400;
        padding: 0px;
        text-align: right;
    }
    .slider-beneficios-bloque-fijo .titulo-con-imagen img{
        width: 150px;
    }
    .slider-secction-text.slider-beneficios-bloque-fijo p{
        margin: 10px 0px;
        padding: 0px;
        text-align: right;
        font-size: 16px;
    }
    .slider-secction-text.slider-beneficios-bloque-fijo .container-button{
        margin: 20px 0px;
    }

    .slider-section-container{
        padding: 0px;
    }

    /* slider-descuentos-bandas */
    .slider-section-bandas-container{
        flex-direction: column-reverse;
        row-gap: 20px;
    }
    .slider-section-text-bandas{
        width: 100%;
        padding-left: 0px;
    }
    .slider-section-text-bandas .slider-secction-text h5 {
        text-align: left;
        font-size: 32px;
    }
    .slider-section-text-bandas.slider-secction-text.slider-beneficios-bloque-fijo.slider-section-text-bandas{
        padding-left: 30px;
        align-items: flex-start!important;
    }
    .slider-section-text-bandas.slider-secction-text h5{
        text-align: left;
        font-size: 32px;
    }
    .titulo-con-imagen{
        column-gap: 10px;
    }
    .slider-section-text-bandas p{
        max-width: 100%;
        text-align: left!important;
    }
    .slider-section-swipper-bandas{
        width: 100%;
    }
    .slider-bandas.slider-descuentos-puntos{
        max-width: 300px;
    }
    .slider-section-swipper-bandas .swipper-beneficios{
        height: 275px;
    }
    .slider-section-swipper-bandas{
        margin: 0px;
    }
    .align-slider-right .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.slider-fotos {
        padding:30px;
    }

    /* slider beneficios cafeteria */
    .slider-beneficios-con-logos{
        margin-left: 30px;
    }
    .swipper-beneficios-con-logo{
        padding: 10px;
    }
    .swipper-beneficios-con-logo .titulo-comentario-cliente{
        font-size: 16px;
    }
    .swipper-beneficios-con-logo .descripcion-comentario-cliente{
        font-size: 12px;
    }
    .swiper-slide.swipper-beneficios-con-logo img {        
        height: 56px;
        width: 70%;
    }
    .beneficios-page-container .child-container-slide.align-slider-right{
margin: 80px 0px;
    }
    .slider-container-beneficios{
        margin: 50px 0px;
    }

}

.slider-secction-text .slider-beneficios-bloque-fijo .algo{
 font-size: 16px;
 }