.preguntas-frecuentes-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.item-tab-menu.nav-link.active {
    background-color: transparent;
    text-decoration: underline !important;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-family: 'Ubuntu', sans-serif;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 1.7em !important;
    text-decoration: none !important;
}

.item-tab-menu {
    color: #666666;
}

.item-tab-menu:hover {
    color: #cacaca;
}

.preguntas-frecuentes-container .accordion {
    --bs-accordion-border-radius: 1.375rem;
    --bs-accordion-inner-border-radius: calc(1.375rem - 1px);
}

.bloque-separate {
    height: 100px;
}

/* Bloque Descargas */

.bloque-descargas {
    display: flex;
    flex-wrap: wrap;
    margin: 100px 0px;
}

.bloque-descarga-box {
    display: flex;
    column-gap: 20px;
    width: 33.333%;
    margin: 30px 0px;
}

.titulo-bloque-descarga-box {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin: 0px;
}

.descripcion-bloque-descarga-box {
    color: rgba(255, 255, 255, 0.829);
    font-weight: 400;
    font-size: 16px;
    margin: 0px;
}

.bloque-descarga-box img {
    width: 50px;
}

.preguntas {
    overflow: hidden;
}

@media only screen and (max-width: 720px) {
    .preguntas-frecuentes-container {
        padding: 0px;
    }

    .preguntas-frecuentes-mobile .tab-content {
        padding: 0px 20px;
    }

    .preguntas-frecuentes-mobile .swiper-wrapper {
        margin: 20px 10px 40px 25px;
    }

    .item-tab-menu {
        width: 200px;
        color: #fff;
        background-color: #3C3C3C;
        padding: 15px;
        border-radius: 15px;
        line-height: 1.2em;
        font-size: 14px;
    }

    .bloque-descargas {
        flex-direction: column;
        padding: 0px 25px;
    }

    .bloque-descarga-box {
        width: 100%;
        margin: 20px 10px;
    }

    button.accordion-button {
        padding: 15px !important;
    }


}
