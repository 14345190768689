.nosotros {
    overflow-x: hidden;
}

.container-nosotros {
    width: 100%;
    background-color: black;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 20px;
}

/* AHORA SI PODES */
/* AHORA SI PODES */

.container-flex-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.container-flex-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-nosotros .child-container-flex-row {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 50px;
}

.child-container-flex-column {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.child-container-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 20px;
}

.child-container-text .text-web {
    width: 75%;
}

.tittle-comision-web {
    color: white;
    font-size: 2em;
    font-weight: 400;
    line-height: 1.3em;
}

.text-web {
    margin: 0px;
    color: white;
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.3em;
}

.text-web.bold {
    font-weight: 600;
}

.tam-section-md {
    padding: 100px 0px;
}

.tam-section-xl {
    padding: 200px 0px;
}

.tam-section-lg {
    /* padding: 0px 0px; */
    padding-bottom: 50px;
}

.tam-margin-xl {
    margin-bottom: 200px;
}

.gradient-section {
    background: rgb(0, 0, 0);
    background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(26, 26, 26, 0.8) 100%);
}

.gradient-section-reverse {
    background: rgb(0, 0, 0);
    background: linear-gradient(270deg, rgba(26, 26, 26, 0.8) 0%, rgba(0, 0, 0, 1) 100%);
}

.child-container-media {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.child-container-media-video video {
    width: 100%;
}

.child-container-text .enQueCreemos {
    width: 50%;
    padding-top: 100px;
}


/* SLIDER */
/* SLIDER */

.slider-section-container {
    padding-top: 150px;
    padding-bottom: 150px;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
}

.section-slider {
    width: 65%;
    display: flex;
    align-items: center;
}

.slider-foto .swiper-slide {
    width: 410px !important;
}

.slider-fotos .swiper-wrapper {
    align-items: center;
}

.slider-fotos .swiper-wrapper:hover {
    cursor: grab;
}

.slider-fotos .swiper-wrapper:active {
    cursor: grabbing;
}

.slider-beneficios-container .swiper-wrapper:hover {
    cursor: grab;
}

.slider-beneficios-container .swiper-wrapper:active {
    cursor: grabbing;
}

.slider-fotos {
    height: 100%;
}

.slider-fotos .swiper-pagination {
    bottom: 15px !important;
}

.slider-fotos .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
}

.swiper-pagination-bullet-active {
    background-color: #032854;
}

.comentarios-clientes {
    width: 320px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 60px 20px;
    /* background-color: #fff; */
    border-radius: 25px;
    border: 2px solid white
}

.comentarios-clientes {
    width: 320px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* background-color: #fff; */
    border-radius: 25px;
    border: 2px solid white
}

.titulo-comentario-cliente {
    margin: 0px;
    color: white !important;
    font-weight: 500;
    font-size: 20px;
    padding: 2px 10px;
}

.descripcion-comentario-cliente {
    max-width: 350px;
    color: white !important;
    font-size: 16px;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    padding: 0px 5px;
}

.slider-secction-text h5 {
    width: 50%;
    font-weight: 700;
    font-size: 50px;
    color: white;
    max-width: 450px;
}

.slider-secction-text {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.slider-secction-text .text-creemos {
    text-align: left !important;
}

.swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.footer-comentario-cliente {
    margin-top: 20px;
    color: #032854;
    font-weight: 600;
    font-size: 15px;
}

.footer-comentario-cliente span {
    color: #032854;
    font-weight: 500;
    font-style: italic;
}

/* SON BLACK */
/* SON BLACK */

.container-sonblack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-sonblack p {
    width: 65%;
    color: white;
}

.container-sonblack h5 {
    width: 65%;
}

/* PREGUNTAS*/
/* PREGUNTAS*/

.container-preguntas-black {
    width: 100%;
    padding: 100px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.child-container-preguntas-black {
    width: 80%;
}

.container-preguntas-black .accordion {
    --bs-accordion-border-radius: 1.375rem;
    --bs-accordion-inner-border-radius: calc(1.375rem - 1px);
}

/* ACCORDION*/
/* ACCORDION*/

.accordion-button {
    font-size: 0.7em !important;
    color: white !important;
    background-color: black !important;
}

.accordion-item .accordion-button {
    border: none !important;
    color: white !important;
}

.accordion-item {
    background-color: #000;
}

.accordion-button::after {
    filter: invert(100%) sepia(0%) saturate(3%) hue-rotate(183deg) brightness(950%) contrast(98%);
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: white !important;
    box-shadow: none !important;
}

.accordion-body {
    color: white !important;
    background-color: #000;
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius);
}


.video-timeline-container {
    position: relative;
}

.video-timeline-container .container-flex-row.gradient-section .tittle-web.enQueCreemos {
    position: absolute;
    left: 11%;
}

@media only screen and (max-width: 768px) {

    .child-container-preguntas-black {
        width: 85%;
    }

    .icon-black-middle {
        width: 140px;
    }

    .gradient-section {
        background: rgb(0, 0, 0);
    }

    .child-container-media {
        width: 100%;
    }

    .slider-secction-text .text-creemos {
        text-align: left !important;
        font-weight: 600;
    }

    .slider-section-container-comision {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .slider-fotos .swiper-slide {
        justify-content: center !important;
        align-items: center;
        width: 100%;
    }

    .slider-secction-text {
        margin-top: 20px;
        width: 100%;
        text-align: center;
    }

    .comentarios-clientes-comision {
        width: 85% !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 15px !important;
    }

    .container-flex-row-start img {
        width: 40px;
        height: 40px;
    }

    .container-flex-row-start {
        align-items: flex-start !important;
    }

    .container-flex-row-start {
        column-gap: 10px !important;
    }

    .child-container-comision {
        column-gap: 10px !important;
    }

    .child-container-comision h1 {
        font-size: 1.3em;
    }

    .container-comision-text {
        width: 85% !important;
    }

    .container-comision-subtext {
        width: 85% !important;
        line-height: 1.5em;
    }

    .child-container-text .enQueCreemos {
        padding-top: 50px;
        width: 100%;
    }

    .child-container-text h1 {
        font-size: 2em !important;
        line-height: 1.3em !important;
    }

    .tittle-web img {
        width: 130px;
    }

    .titulo-comision {
        width: 100% !important;
        font-size: 1em !important;
    }

    .descripcion-comision {
        width: 100% !important;
        font-size: 0.9em !important;
    }

    .slider-section-container {
        height: auto;
    }

    .section-slider {
        margin: 20px auto;
        width: 100%;
    }

    .slider-secction-text h4 {
        font-weight: 500;
        font-size: 18px;
        text-align: center;
    }

    .descripcion-comentario-cliente {
        padding: 0px 10px;
        line-height: 1.3em;
    }

    .slider-secction-text h5 {
        width: 85%;
        font-weight: 700;
        font-size: 3em;
        color: white;
        padding: 0px !important;
        text-align: left;
    }

    .container-sonblack p {
        width: 80%;
        text-align: left;
    }

    .comentarios-clientes {
        height: 400px;
        margin-left: 20px !important;
        margin-right: 10px !important;
        justify-content: center;
        align-items: flex-start;
    }

    .slider-secction-text h4 {
        line-height: 20px;
    }

    .slider-section-container {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .container-preguntas-black {
        padding: 50px 0px;
    }

    .footer-comentario-cliente {
        margin-top: 0px;
    }

    .container-nosotros .child-container-text {
        width: 100%!important;
    }

    .child-container-text .text-web {
        width: 100%;
    }

    .tittle-comision-web {
        color: white;
        font-size: 1.5em;
        font-weight: 700;
        line-height: 1.3em;
    }

    .slider-section-container-comision {
        row-gap: 25px;
    }

    .gradient-section .child-container-flex-row {
        width: 85%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .gradient-section-reverse .child-container-flex-row {
        width: 85%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .gradient-section-reverse {
        background: black;
    }

    .tam-margin-xl {
        margin-bottom: 50px;
    }

    .child-container-media video {
        width: 90%;
    }

    .tam-section-xl {
        padding: 50px 0px;
    }

    .container-nosotros .child-container-flex-row {
        width: 85%;
        flex-direction: column-reverse;
        row-gap: 25px;
    }

    .text-los-mejores-descuentos {
        width: 85%;
    }

    .flex-row {
        width: 85%;
    }

    .container-son-black {
        align-items: center !important;
        padding-right: 0px !important;
        text-align: center !important;
    }

    .container-son-black p {
        text-align: left !important;
    }

    .child-container-media-video-mobile {
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }

    .child-container-media-video-mobile video {
        width: 100%;
        height: 100%;
    }

    .video-timeline-secction {
        width: 100%;
    }

}