body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*===== UBUNTU FONT =====*/

@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu-light-webfont.woff2') format('woff2');
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu-light-italic-webfont.woff2') format('woff2');
  font-display: swap;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu-regular-webfont.woff2') format('woff2');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu-regular-italic-webfont.woff2') format('woff2');
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu-medium-webfont.woff2') format('woff2');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu-medium-italic-webfont.woff2') format('woff2');
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu-bold-webfont.woff2') format('woff2');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/fonts/ubuntu-bold-italic-webfont.woff2') format('woff2');
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

/*=======================*/